import { useEffectOnce } from "react-use";
import { navigate } from "gatsby";

function CustomWorkout() {
  useEffectOnce(() => {
    navigate("/workouts");
  });

  return null;
}

export default CustomWorkout;
